<template>
    <li
        v-if="isDropdown && isStoreLanguageSwitcherEnabled"
        class="language-switcher"
    >
        <div class="language-switcher-dropdown">
            <WithClickOutsideDetection :handler="isOpen ? 'close' : null">
                <div class="dropdown">
                    <div class="drop-button" @click="toggleOpen()">
                        {{ $t('Language: ') }}
                        <span class="active">{{ activeLanguageName }}</span>
                        <Icon :icon="dropdownIcon" width="16px" height="16px" />
                    </div>

                    <div v-if="isOpen" class="content">
                        <LanguageSwitcherContent
                            :language-links="languageSwitcherLinks"
                            :active-language-id="activeLanguageName"
                        />
                    </div>
                </div>
            </WithClickOutsideDetection>
        </div>
    </li>
    <div v-else-if="isStoreLanguageSwitcherEnabled" class="language-switcher">
        <LanguageSwitcherContent
            :language-links="languageSwitcherLinks"
            :active-language-id="activeLanguageName"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { STORE_LANGUAGE_SWITCHER_MAP_NAME } from '@localeConfig/keys';

import WithClickOutsideDetection from '@molecules/WithClickOutsideDetection/WithClickOutsideDetection';

import { ChevronUp, ChevronDown } from '@eobuwie-ui/icons/v2/navigation';
import { Icon } from '@eobuwie-ui/components/Icon/v1';

import LanguageSwitcherContent from './LanguageSwitcherContent';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'LanguageSwitcher',

    components: {
        LanguageSwitcherContent,
        WithClickOutsideDetection,
        Icon,
    },

    props: {
        isDropdown: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isOpen: false,
        };
    },

    computed: {
        ...mapConfigGetters(['localeConfigByKey', 'locale']),

        isStoreLanguageSwitcherEnabled() {
            return !!Object.keys(this.storeLanguageSwitcherMap).length;
        },

        storeLanguageSwitcherMap() {
            return (
                this.localeConfigByKey(STORE_LANGUAGE_SWITCHER_MAP_NAME) || {}
            );
        },

        activeLanguageName() {
            return this.locale.slice(0, 2).toUpperCase();
        },

        languageSwitcherLinks() {
            return Object.entries(this.storeLanguageSwitcherMap).map(
                ([name, path]) => {
                    return {
                        path,
                        name,
                    };
                }
            );
        },

        dropdownIcon() {
            return this.isOpen ? ChevronUp : ChevronDown;
        },
    },

    methods: {
        toggleOpen() {
            this.isOpen = !this.isOpen;
        },

        // eslint-disable-next-line vue/no-unused-properties
        close() {
            this.isOpen = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.language-switcher {
    .dropdown {
        @apply relative font-ui-body-s;
    }

    .drop-button {
        @apply flex items-center cursor-pointer gap-x-ui-1;
    }

    .content {
        @apply absolute top-full right-0 z-6 mt-ui-2;
        @apply border-ui-1 border-solid border-ui-container-stroke-100-default;
    }

    .active {
        @apply font-ui-body-s-bold;
    }
}
</style>
