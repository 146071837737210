<template>
    <div class="language-switcher-content">
        <BodyText class="switcher-text">
            {{ $t('What language do you want to use?') }}
        </BodyText>
        <SegmentedControl
            :selected="activeLanguageId"
            @select="navigateToPath($event)"
        >
            <template #default="{ props, on }">
                <SegmentedControlButton
                    v-for="{ path, name } in languageLinks"
                    :key="path"
                    :value="name"
                    v-bind="props"
                    v-on="on"
                >
                    {{ name }}
                </SegmentedControlButton>
            </template>
        </SegmentedControl>
    </div>
</template>

<script>
import {
    SegmentedControl,
    SegmentedControlButton,
} from '@eobuwie-ui/components/SegmentedControl/v1';
import { BodyText } from '@eobuwie-ui/components/BodyText/v1';

export default {
    name: 'LanguageSwitcherContent',

    components: {
        BodyText,
        SegmentedControlButton,
        SegmentedControl,
    },

    props: {
        activeLanguageId: {
            type: String,
            required: true,
        },

        languageLinks: {
            type: Array,
            required: true,
        },
    },

    methods: {
        navigateToPath(selectedName) {
            if (selectedName === this.activeLanguageId) {
                return;
            }

            const { path } =
                this.languageLinks.find(({ name }) => name === selectedName) ||
                {};

            if (path) {
                window.location.href = path;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.language-switcher-content {
    @apply p-ui-4;
    @apply bg-ui-container-default-default;
    @apply whitespace-nowrap;

    .switcher-text {
        @apply mb-ui-3;
    }
}
</style>
